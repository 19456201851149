
import dialogShell from '@/components/common/dialog-shell/index.vue';
import {
    defineComponent, ref, Ref, watch, reactive
} from 'vue';
import { message } from '@/methods/rule';
import { list, ListHeaderItem, RequestData } from '@/components/common/list';
import HttpRequest from '@/util/axios.config';
import noticeBase from '@/util/notice.base';
import { AddFormType, SelectType } from './all.type';

const header: Array<ListHeaderItem> = [{
    name: 'Account',
    label: WordList.TabelPersonUserInHtmlUID
}, {
    name: 'Name2',
    label: WordList.TabelPersonUserInHtmlName
}, {
    name: 'Email',
    label: WordList.TabelPropertyManageEmail
}];

export default defineComponent({
    components: {
        dialogShell,
        list
    },
    setup(props, { emit }) {
        const rules = {
            MessageTitle: [{
                required: true,
                message: WordList.RuleMessageTitleEmpty,
                trigger: 'change'
            }, {
                validator: message.checkTitle,
                trigger: 'change'
            }],
            Message: [{
                required: true,
                message: WordList.RuleMessageEmpty,
                trigger: 'change'
            }, {
                validator: message.checkMessage,
                trigger: 'change'
            }],
            HaveDevice: [{
                required: true,
                message: WordList.TabelPersonKeyDevice,
                trigger: 'blur'
            }]
        };
        const searchAccount = ref('');
        const listRequestData: RequestData = reactive({
            url: 'getpersonalmessageSys',
            param: {
                Key: '',
                UnSelect: ''
            }
        });
        const updateList = ref(0);
        watch(searchAccount, () => {
            listRequestData.param.Key = searchAccount.value;
            updateList.value += 1;
        });
        const formData: Ref<AddFormType> = ref({
            MessageTitle: '',
            ClientType: '0',
            Message: '',
            isAllMessage: 0,
            Receiver: '',
            HaveDevice: ''
        });
        const total = ref(0);
        const getTotal = (res: number) => {
            total.value = res;
        };

        // 保存选择发送账号
        const selectDevice = (accounts: Array<string>, items: Array<SelectType>) => {
            formData.value.isAllMessage = items.length === total.value ? 1 : 0;
            formData.value.Receiver = JSON.stringify(accounts);
        };

        const formRef: Ref<any> = ref(null);
        const submit = () => {
            if (formData.value.Receiver.length === 0) {
                formData.value.HaveDevice = '';
            } else {
                formData.value.HaveDevice = 'true';
            }
            formRef.value.validate((valid: boolean) => {
                if (!valid) return false;
                HttpRequest.post('addpersonalmessage', formData.value, [(data: { msg: string }) => {
                    noticeBase.messageBox('alert', data.msg, ' ', 'success')(() => false);
                    emit('close');
                    emit('success');
                }, false]);
                return true;
            });
        };
        return {
            rules,
            formData,
            header,
            updateList,
            listRequestData,
            getTotal,
            selectDevice,
            searchAccount,
            formRef,
            submit
        };
    }
});
