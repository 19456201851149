
import { defineComponent, Ref, ref } from 'vue';
import {
    listPagination, RequestData
} from '@/components/common/list';
import addButton from '@/components/common/add-button/index.vue';
import messageInfoDialog from '@/components/view/installer/single/message-info-dialog';
import messageAddDialog from '@/components/view/installer/single/message-add-dialog';
import {
    initData, isShowMessageDialog, formData, getMessageInfo, isShowMessageAddDialog, addMessage
} from './util';

export default defineComponent({
    components: {
        listPagination,
        addButton,
        messageInfoDialog,
        messageAddDialog
    },
    setup() {
        const {
            headers,
            searchKeyList
        } = initData();
        const updateToList = ref(0);
        const listRequestData: Ref<RequestData> = ref({
            url: 'getpersonalmessage',
            param: {
                serchKey: 'Message',
                serchValue: ''
            }
        });
        return {
            headers,
            updateToList,
            listRequestData,
            searchKeyList,
            isShowMessageDialog,
            formData,
            getMessageInfo,
            isShowMessageAddDialog,
            addMessage
        };
    }
});
