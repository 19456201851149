import { ref, Ref } from 'vue';
import { ListHeaderItem } from '@/components/common/list';
import { MessageForm } from '@/components/view/installer/single/message-info-dialog';

const initData = () => {
    const headers: Array<ListHeaderItem> = [{
        name: 'Title',
        label: WordList.MessageListTanleTitle
    }, {
        name: 'Message',
        label: WordList.TabelMessageBoxMessage
    }, {
        name: 'NickNames',
        label: WordList.TabelMessageBoxReceiver
    }, {
        name: 'Type',
        label: WordList.MessageListTanleType
    }, {
        name: 'CreateTime',
        label: WordList.TabelMessageBoxCreateTime
    }];
    const searchKeyList = [{
        label: WordList.TabelMessageBoxMessage,
        name: 'Message'
    }, {
        label: WordList.TabelMessageBoxReceiver,
        name: 'Receiver'
    }];
    return {
        headers,
        searchKeyList
    };
};
const isShowMessageDialog = ref(false);
const formData: Ref<MessageForm> = ref({
    Title: '',
    Type: '',
    Message: '',
    NickNames: '',
    CreateTime: ''
});
type messageType = 'Title' | 'Type' | 'Message' | 'NickNames' | 'CreateTime';
function getMessageInfo(value: MessageForm) {
    const meaasgeInfo = JSON.parse(JSON.stringify(value));
    Object.keys(formData.value).forEach((item) => {
        formData.value[item as messageType] = meaasgeInfo[item as messageType];
    });
    isShowMessageDialog.value = true;
}
const isShowMessageAddDialog = ref(false);
function addMessage() {
    isShowMessageAddDialog.value = true;
}

export default null;
export {
    initData,
    isShowMessageDialog,
    formData,
    getMessageInfo,
    isShowMessageAddDialog,
    addMessage
};